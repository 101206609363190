import { Tooltip } from 'antd';
import { useMemo } from 'react';
import styled from 'styled-components';

import PremiumIconFilled from '../../assets/icons/PremiumIconFilled';
import SchoolPlanIcon from '../../assets/icons/SchoolPlanIcon';
import theme from '../../assets/theme';
import { useMetrics } from '../../hooks/useMetrics';
import { LicenseCoarseLevels, TenantLicense } from '../../utils/tenantPlanUtils';
import { IconWrapper } from './IconWrapper';

const PlanTypeLabel = ({
  tenantPlan,
  onlyIcon,
  isGeneratorCard,
  generatorPlan,
  isPremiumGenerator,
}: {
  tenantPlan?: TenantLicense;
  onlyIcon?: boolean;
  isGeneratorCard?: boolean;
  generatorPlan?: LicenseCoarseLevels | null;
  isPremiumGenerator: boolean | null | undefined;
}) => {
  const metrics = useMetrics();
  const isPremiumPlanGenerator =
    generatorPlan === LicenseCoarseLevels.premium ||
    (generatorPlan === undefined && isPremiumGenerator);
  const isEnterprisePlanGenerator = generatorPlan === LicenseCoarseLevels.enterprise;

  tenantPlan = tenantPlan ?? new TenantLicense(false, false);
  const hasAccessToGenerator =
    (isPremiumPlanGenerator && (tenantPlan.isPremium() || tenantPlan.isEnterprise())) ||
    (isEnterprisePlanGenerator && tenantPlan.isEnterprise());

  const getAccessStatus = (
    plan: LicenseCoarseLevels,
    hasAccess: boolean | null | undefined,
  ) => {
    const statuses = {
      premium: {
        withAccess: {
          tooltip:
            'As a Curipod premium subscriber, you get this premium customizable lesson for free',
          label: 'Premium',
        },
        withoutAccess: {
          tooltip: 'Curipod premium subscribers get this customizable lesson for free',
          label: 'Premium',
        },
      },
      enterprise: {
        withAccess: {
          tooltip:
            'As a Curipod school plan subscriber, you get this customizable lesson for free',
          label: 'School Plan',
        },
        withoutAccess: {
          tooltip:
            'Curipod school plan subscribers get this customizable lesson for free',
          label: 'Request school access to unlock',
        },
      },
      freemium: { tooltip: 'Free customizable lesson', label: '' },
    };

    if (plan === LicenseCoarseLevels.premium || plan === LicenseCoarseLevels.enterprise) {
      return hasAccess ? statuses[plan].withAccess : statuses[plan].withoutAccess;
    }
    return statuses.freemium;
  };

  const planType =
    generatorPlan ||
    (isPremiumGenerator ? LicenseCoarseLevels.premium : LicenseCoarseLevels.freemium);
  const hasAccess =
    (planType === LicenseCoarseLevels.premium &&
      (tenantPlan.isPremium() || tenantPlan.isEnterprise())) ||
    (planType === LicenseCoarseLevels.enterprise && tenantPlan.isEnterprise());

  const accessStatus = useMemo(() => {
    return getAccessStatus(planType, hasAccess);
  }, [planType, hasAccess]);

  return (
    <Tooltip title={accessStatus.tooltip}>
      <Label
        $align="right"
        $isGeneratorCard={isGeneratorCard}
        $hasClick={isEnterprisePlanGenerator && !hasAccessToGenerator && !onlyIcon}
        onClick={() => {
          if (isEnterprisePlanGenerator && !hasAccessToGenerator && !onlyIcon) {
            metrics.logEvent('Enterprise.Label.Upgrade.Clicked', {
              location: 'enterpriseLabel',
            });
            window.open(`/quotes`, '__blank');
          }
        }}
      >
        <PlanIcon
          isPremiumPlanGenerator={isPremiumPlanGenerator}
          isEnterprisePlanGenerator={isEnterprisePlanGenerator}
          onlyIcon={onlyIcon}
          hasAccessToGenerator={hasAccessToGenerator}
        />
        {!onlyIcon && <>{accessStatus.label}</>}
      </Label>
    </Tooltip>
  );
};

export const PlanIcon = ({
  isPremiumPlanGenerator,
  isEnterprisePlanGenerator,
  onlyIcon,
  hasAccessToGenerator,
}: {
  isPremiumPlanGenerator: boolean | null | undefined;
  isEnterprisePlanGenerator: boolean;
  onlyIcon?: boolean;
  hasAccessToGenerator: boolean | null | undefined;
}) => {
  return (
    <IconWrapper
      width="12px"
      height="12px"
      style={{
        color: hasAccessToGenerator ? theme.colors.white : theme.colors.yellow,
        marginRight: onlyIcon ? 0 : 5,
      }}
    >
      {isEnterprisePlanGenerator && <SchoolPlanIcon />}
      {isPremiumPlanGenerator && <PremiumIconFilled />}
    </IconWrapper>
  );
};

const Label = styled.div<{
  $align: 'right' | 'left';
  $isGeneratorCard?: boolean;
  $hasClick?: boolean;
}>`
  position: ${({ $isGeneratorCard }) => ($isGeneratorCard ? 'absolute' : 'unset')};
  bottom: 5px;
  right: ${({ $align }) => ($align === 'right' ? '5px' : 'unset')};
  left: ${({ $align }) => ($align === 'left' ? '5px' : 'unset')};
  background: #00000099;
  border-radius: 10px;
  color: ${theme.colors.white};
  font-weight: 600;
  font-size: 12px;
  padding: 2px 10px;
  height: 20px;
  display: flex;
  align-items: center;
  box-shadow: 0px 4px 4px #00000025;
  width: fit-content;
  cursor: ${({ $hasClick }) => ($hasClick ? 'pointer' : 'unset')};
`;

export default PlanTypeLabel;
